@import "../../../main.scss";
@import "../../../theme.scss";

.addCompetence:hover,
.addCompetence:focus {
	color: $primaryColor;
	border-color: $primaryColor;
}

.deleteContainer {
	margin-top: 2%;
	width: auto;
	font-size: 14px;

	&:hover {
		background-color: #e8e7e7;
	}
}

.input_container {
	margin-right: 5%;
	display: flex;
	align-items: center;
}

.delete {
	outline: none;
	background-color: transparent;
	border: none;
	float: right;

	&:hover {
		cursor: pointer;
	}
}

textarea::placeholder,
.numberInput::placeholder {
	opacity: 0.25;
}

@media only screen and (min-width: 811px) {
	.container {
		.button {
			@include layout(row, flex-start, flex-start);
		}

		.firstLine {
			@include layout(column, flex-start, flex-start);
			width: 100%;

			:global {
				.ant-row {
					width: 100% !important;
				}
			}

			.checkbox {
				@include layout(row, flex-start, flex-start);
			}

			textarea,
			.numberInput {
				border: 0.5px solid #d9d9d9;
				outline: none;
				resize: none;
				box-shadow: none;
				border-radius: 4px !important;
				color: rgb(0, 0, 0) !important;
				width: 100%;
				height: 2.2rem;
				padding: 5px 5px 5px 1%;

				&:hover,
				&:focus,
				&:active {
					border: 0.5px solid #d9d9d9;
					outline: none;
					resize: none;
					box-shadow: none;
					border-radius: 4px !important;
				}
			}

			.customSelect {
				text-align: left;
			}
		}

		.secondLine {
			margin-top: 2rem;
			width: 100%;
			@include layout(row, space-between, flex-start);

			.firstColumn {
				@include layout(row, space-between, center);
				width: fit-content;
			}

			.secondColumn {
				@include layout(row, flex-end, center);
			}
		}
	}
}

@media only screen and (max-width: 810px) {
	.container {
		.firstLine {
			@include layout(column, flex-start, flex-start);
			width: 100%;

			:global {
				.ant-row {
					width: 100% !important;
				}
			}

			.checkbox {
				@include layout(row, flex-start, flex-start);
			}

			.container2 {
				width: 100%;
				margin: 0;
			}

			textarea,
			.numberInput {
				border: 0.5px solid #d9d9d9;
				outline: none;
				resize: none;
				box-shadow: none;
				border-radius: 4px !important;
				padding: 5px 5px;
				color: rgb(0, 0, 0) !important;
				width: 100%;
				height: 2.2rem;

				&:hover,
				&:focus,
				&:active {
					border: 0.5px solid #d9d9d9;
					outline: none;
					resize: none;
					box-shadow: none;
					border-radius: 4px !important;
				}
			}
		}

		.secondLine {
			margin-top: 2rem;
			width: 100%;
			@include layout(row, center, flex-start);

			.firstColumn {
				@include layout(row, space-evenly, center);
				width: fit-content;
			}
		}
	}
}

@media only screen and (max-width: 450px) {
	.container {
		.firstLine {
			@include layout(column, flex-start, flex-start);
			width: 100%;

			:global {
				.ant-row {
					width: 100% !important;
				}
			}

			.checkbox {
				@include layout(row, flex-start, flex-start);
			}

			.container2 {
				margin: 0;
			}

			textarea,
			.numberInput {
				border: 0.5px solid #d9d9d9;
				outline: none;
				resize: none;
				box-shadow: none;
				border-radius: 4px !important;
				padding: 5px 5px;
				color: rgb(0, 0, 0) !important;
				width: 100%;
				height: 2.2rem;

				&:hover,
				&:focus,
				&:active {
					border: 0.5px solid #d9d9d9;
					outline: none;
					resize: none;
					box-shadow: none;
					border-radius: 4px !important;
				}
			}
		}

		.secondLine {
			margin-top: 1rem;
			width: 100%;
			@include layout(row, space-evenly, center);

			.firstColumn {
				@include layout(row, space-evenly, center);
				margin-bottom: 5%;
				width: fit-content;
			}
		}
	}
}
