@import "../../../main.scss";
@import "../../../theme.scss";

.ListItem {
	text-align: left;
	//border: 1px solid lightgray !important;
	border-radius: 10px;
	margin-bottom: 1em;
	margin-top: 1em;

	li {
		padding-left: 0 !important;
		display: block !important;

		div {
			padding-bottom: 0 !important;
		}
	}
}

.antSelect > div {
	height: 35.5px !important;
}

.antSelect:hover,
.antSelect > div:hover,
.antSelect > div:focus,
.antSelect > div:focus-visible .antSelect > div:active {
	border-color: #d9d9d9 !important;
}

.customCol {
	display: inline-flex !important;
	justify-content: center;
	align-items: center;
}

@media only screen and (min-width: 1101px) {
	.filterButton {
		display: none !important;
	}
}

@media only screen and (max-width: 1100px) {
	.listItemMeta > div {
		width: 100% !important;
	}

	.listItemMeta {
		display: block !important;
	}
}
