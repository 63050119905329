@import "../../main.scss";
@import "../../theme.scss";

.search {
	margin-top: 24px;
	margin-bottom: 10px;
	max-width: 280px;
}

.editButton,
.editButton:hover {
	outline: none;
	border: none;
	background: transparent;
}

@media only screen and (min-width: 1601px) {
	.filterButton {
		display: none !important;
	}
}

@media only screen and (max-width: 601px) {
	.search {
		width: 130px;
	}
}

@media only screen and (max-width: 1600px) {
	.filters,
	.searchBox {
		display: none !important;
	}

	.buttonsContainer > * {
		display: block;
	}

	.listItemMeta > div {
		width: 100% !important;
	}

	.listItemMeta {
		display: block !important;
	}
}
