@import "../../main.scss";
@import "../../theme.scss";

.wrapper {
	font-family: $font;
	height: 100vh;
	@include layout(row, space-between, center);
	margin: 0 7% 0 7%;
	.container {
		@include layout(column, center, center);
		.customCol {
			display: flex !important;
			align-items: center !important;
			justify-content: center !important;
		}
	}
}

@media only screen and (max-width: 801px) {
	.wrapper {
		font-family: $font;
		width: 86%;
		min-height: 75vh;
		@include layout(row, space-between, center);
		margin: 5% 7% 0 7%;

		.container {
			@include layout(column, center, center);
			.customCol {
				display: flex !important;
				align-items: center !important;
				justify-content: center !important;
			}
			h1 {
				font-size: 1rem;
			}
		}
	}
}

@media only screen and (max-width: 450px) {
	.wrapper {
		font-family: $font;
		width: 86%;
		min-height: 75vh;
		@include layout(row, space-between, center);
		margin: 5% 7% 0 7%;
		.container {
			@include layout(column, center, center);
			.customCol {
				display: flex !important;
				align-items: center !important;
				justify-content: center !important;
			}
			h1 {
				font-size: 1rem;
			}
		}
	}
}
