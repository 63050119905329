$primaryColor: #376e6f;
$secondColor: #1c3334;
$forthColor: #dbd7d7;
$lineColor: #e8e7e7;
//rosu opac
$redOpac: "rgba(255,0,0,0.7)";
$red: "rgb(255,0,0)";
//verde opac
$green: "rgba(55, 110, 111, 0.7)";
$greenOpac: "rgba(55, 110, 111, 0.2)";
$lightGreen: "#456D6E";
$white: white;
$black: black;
$font: "Montserrat";
$thirdGreen: #3b8d7f;
$limeGreen: #7fc881;
$yellow: #f9f871;
$orange: #ff8946;
$lightRed: #ef6873;
$pink: #c05e91;
$purple: #805e94;
