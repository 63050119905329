.customTableRow {
	font-weight: bold;
	background-color: #f1f1f1f1;
}

.searchBox {
	margin-top: 24px;
	margin-bottom: 24px;
	width: 100% !important;
}

@media only screen and (min-width: 1601px) {
	.filterButton {
		display: none !important;
	}
}

@media only screen and (max-width: 1600px) {
	.filters,
	.searchBox {
		display: none !important;
	}

	.buttonsContainer > * {
		display: block;
	}

	.listItemMeta > div {
		width: 100% !important;
	}

	.listItemMeta {
		display: block !important;
	}
}
