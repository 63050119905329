@import "../../main.scss";
@import "../../theme.scss";

.ListItem {
	text-align: left;
}

.ListItem > ul {
	width: 100%;
}

.message {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 20px;
	margin-top: 25px;
}

.title {
	font-size: 25px;
	font-weight: bold;
}

.subTitle {
	font-size: 20px;
	font-weight: bold;
}

.skill {
	background-color: $primaryColor;
	color: white;
	border-radius: 12px;
	padding: 5px;
	min-width: 125px;
}

.subtitle {
	margin: 20px 0 10px 0;
	font-size: 25px;
	font-weight: bold;
	text-align: left;
}

.description {
	font-size: 16px;
	font-style: italic;
	text-align: left;
	a,
	a:link,
	a:visited {
		color: blue;
	}
	a:hover {
		font-weight: bold;
		text-decoration: underline;
	}
}
