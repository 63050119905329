@font-face {
	font-family: "Montserrat";
	font-style: black;
	font-weight: 900;
	src: url("../fonts/Montserrat-Black.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat";
	font-style: bold;
	font-weight: 700;
	src: url("../fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat";
	font-style: semibold;
	font-weight: 600;
	src: url("../fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	src: url("../fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat";
	font-style: light;
	font-weight: 300;
	src: url("../fonts/Montserrat-Light.ttf") format("truetype");
}
