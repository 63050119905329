@import "../../main.scss";
@import "../../theme.scss";

.firstChild {
	margin: 2% 0;
	font-family: $font;
	p {
		font-weight: bold;
		color: $primaryColor;
		font-size: 1.4rem;
	}
}

.Row {
	height: auto;
	width: 100%;
	.filter {
		margin-top: 100px;
		margin-bottom: 10px;
	}
	.ListItem {
		text-align: left;
	}
}

.deleteButton {
	&:hover,
	&:focus,
	&:active {
		outline: none;
		border: none;
		box-shadow: none;
		background: transparent;
		opacity: 0.8;
	}
	&:disabled,
	&:disabled:hover {
		color: white;
		opacity: 0.6;
	}
}


@media only screen and (max-width: 1000px) {
	.deleteButton {
		padding: 0.3rem 0.6rem;
		font-size: 90%;
	}

	.notificationText {
		padding-bottom: 0.5rem;
	}
}