@import "../../main.scss";
@import "../../theme.scss";

.wrapper {
	@include layout(row, space-between, flex-start);
	width: 100%;
	font-family: $font;
	margin: 5% 15% 5% 5%;

	.tableofContents {
		@include layout(column, space-between, center);
		text-align: left;
		width: 16%;
		z-index: 0;

		ul {
			z-index: -1;
			position: fixed;
			padding: 2%;
			width: 16%;
			box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
			margin-bottom: 10%;
			li {
				margin: 0 0;
				padding: 3% 3%;
				a {
					color: $primaryColor;
					font-weight: bold;
				}
			}
		}
	}
	.content {
		@include layout(column, flex-start, flex-start);
		width: 76%;
		h3 {
			color: #ababab;
			font-weight: bold;
			margin-top: 1%;
			font-size: 0.9rem;
		}
	}
	.tableElements {
		@include layout(column, space-between, flex-start);
		border-bottom: 0.3px solid $lineColor;
		.container {
			@include layout(column, flex-start, flex-start);
			width: 100%;
			padding-right: 5%;
			text-align: left;
			h2 {
				font-weight: bold;
				margin: 0 0;
			}
			a,
			p {
				font-size: 0.9rem;
				line-height: 1.8;
				margin: 2% 0;
			}
			br {
				font-size: 24%;
				margin-bottom: 2%;
			}
		}
	}
}

@media only screen and (max-width: 810px) {
	.wrapper {
		@include layout(row, space-between, flex-start);
		width: 90%;
		font-family: $font;
		margin: 5% 5% 5% 5%;

		.tableofContents {
			@include layout(column, space-between, center);
			text-align: left;
			width: 25%;

			ul {
				padding: 3%;
				box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
				li {
					margin: 0 0;
					padding: 3% 3%;
					a {
						color: $primaryColor;
						font-weight: bold;
					}
				}
			}
		}
		.content {
			@include layout(column, flex-start, flex-start);
			width: 70%;
			h3 {
				color: #ababab;
				font-weight: bold;
				margin-top: 1%;
				font-size: 0.9rem;
			}
		}
		.tableElements {
			@include layout(column, space-between, flex-start);
			border-bottom: 0.3px solid $lineColor;
			.container {
				@include layout(column, flex-start, flex-start);
				width: 100%;
				padding-right: 5%;
				text-align: left;
				h2 {
					font-weight: bold;
					margin: 0 0;
				}
				a,
				p {
					font-size: 0.9rem;
					line-height: 1.8;
					margin: 2% 0;
				}
				br {
					font-size: 24%;
					margin-bottom: 2%;
				}
			}
		}
	}
}

@media only screen and (max-width: 450px) {
	.wrapper {
		@include layout(column, center, center);
		width: 90%;
		font-family: $font;
		margin: 5% !important;

		.tableofContents {
			@include layout(column, space-between, center);
			text-align: left;
			width: 80%;
			margin-bottom: 5%;

			ul {
				padding: 3%;
				box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
				li {
					margin: 0 0;
					padding: 3% 3%;
					a {
						color: $primaryColor;
						font-weight: bold;
					}
				}
			}
		}
		.content {
			@include layout(column, flex-start, flex-start);
			width: 90%;
			h3 {
				color: #ababab;
				font-weight: bold;
				margin-top: 1%;
				font-size: 0.9rem;
			}
		}
		.tableElements {
			@include layout(column, space-between, flex-start);
			border-bottom: 0.3px solid $lineColor;
			.container {
				@include layout(column, flex-start, flex-start);
				width: 100%;
				text-align: left;
				h2 {
					font-weight: bold;
					margin: 0 0;
				}
				a,
				p {
					font-size: 0.9rem;
					line-height: 1.8;
					margin: 2% 0;
				}
				br {
					font-size: 24%;
					margin-bottom: 2%;
				}
			}
		}
	}
}
