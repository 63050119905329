.antItem {
	height: 2.2rem;
}

.autocompleteWrapper {
	position: relative;

	.autocompleteDropdown {
		display: none;
		background-color: #fff;
		position: absolute;
		left: 0;
		width: 100%;
		z-index: 999;
		text-align: left;
		outline: none;
		box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
			0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

		&.active {
			display: block;
		}

		.autocompleteItem {
			padding: 5px 0;
			padding-left: 10px;
			border-bottom: none !important;
			font-family: inherit;
			cursor: pointer;

			&:hover {
				background-color: #f5f5f5;
			}
		}
	}
}
