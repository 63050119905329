@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./assets/css/fonts.css";

@layer base {
	body {
		@apply font-Montserrat m-0 text-secondary;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		--antd-wave-shadow-color: black;
	}

	code {
		font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
			monospace;
	}
}

@layer utilities {
	.strip-ant-selector {
		.ant-select-selector {
			border: none !important;
			padding: 0;
			cursor: default;
			background-color: transparent;

			.ant-select-selection-overflow {
				justify-content: flex-end;

				.ant-select-selection-overflow-item-rest {
					display: block;
					align-self: auto;

					.ant-select-selection-item {
						padding: 0;
						margin: 0;
						line-height: normal;
						height: auto;
						display: block;
						background-color: transparent;
						border: none;
					}
				}
			}
		}

		.ant-select-arrow {
			display: none;
		}
	}

	.grow-list-col {
		.ant-row > div {
			display: flex;

			.ant-col {
				flex-grow: 1;

				.ant-card {
					display: flex;
					flex-direction: column;

					.ant-card-body {
						flex-grow: 1;
						padding-bottom: 4px;
					}
				}
			}
		}
	}
}
